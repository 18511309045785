import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockIntroduction from "../components/blockIntroduction"
import TitleTextCol from "../components/titleTextCol"
import Cta from "../components/cta"
import Accordions from "../components/accordions"
import MetaImage from "../images/featured/loyall-featured-image-integraties-light.jpg"
import HooxFavicon from "../images/favicon-hoox.png"
import ThreeImagesGallery from "../components/threeImagesGallery"
import Image from "../components/elements/image"
import { Container, Row, Col } from "react-bootstrap"
import LabelIntroduction from "../components/labelIntroduction"
import HeaderImageArrowDown from "../components/headerImageArrowDown"

const IntegrationsPage = props => {
  return (
    <Layout>
      <Seo
        title="Loyall – Met integraties zorgen we dat al je systemen met elkaar in verbinding staan"
        image={{ src: MetaImage, height: 628, width: 1200 }}
        description="Ieder bedrijf verzamelt data, maar wat je doet met deze data bepaalt je resultaat. Met integraties leggen we duurzame verbindingen waardoor je al je werkzaamheden vanaf één locatie kunt doen."
        pathname={props.location.pathname}
        favicon={HooxFavicon}
      />
      <div className="page-wrapper">
        <LabelIntroduction
          label="integrations"
          title="We integrate your favourite tools."
          subtitle="Pas wanneer al je systemen met elkaar in verbinding staan kunnen ze voor jou en je organisatie gaan werken."
        />
        <Container className="image-header">
          <Image fadeIn={true} imgsrc="loyall-label-integraties-header.jpg"/>
        </Container>
        <TitleTextCol
          title="Ieder bedrijf verzamelt data. Je gaat het verschil maken als je de kracht van deze data benut."
          paragh1="Hoe behoud je de connectie met je huidige en toekomstige klanten? Wat doe je met alle verzamelde data? Waar verzamel je eigenlijk data? Met integraties zorgen we ervoor dat je sales, marketing, HR en customer service vanaf één locatie kunt doen. Automatiseer je taken, maak dashboards met real-time data en biedt een uitmuntende service. We brengen development en marketing dichter bij elkaar dan ooit, zodat we duurzame verbindingen kunnen maken. Met integraties heb je de sleutel tot het succesvol benutten van jouw digital lifecycle."
        />
        <BlockIntroduction
          title="Hoe het werkt"
          intro={
            <h3>
              Wij laten je systemen samenwerken zodat ze voor jou gaan werken.
            </h3>
          }
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
        />   
        <HeaderImageArrowDown
          imageBig={"loyall-label-integraties-hoe-het-werk-1.jpg"}
          imageSmall={"loyall-label-integraties-hoe-het-werk-2.jpg"}
          elementId="integraties-two-images"
          delay={200}
          animationDuration={600}
        />
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 4 }}>
              <Accordions
                content={[
                  {
                    "title": "Connect your systems", 
                    "text": "Werken jouw marketing, sales en recruitmentafdeling al samen om het maximale resultaat te behalen? Om meer kandidaten, klanten of gebruikers te genereren is het belangrijk om elk team met dezelfde data te laten werken, vanaf dezelfde plek. Dit doe je door de juiste connecties te leggen tussen verschillende software. Op deze manier wordt samenwerken veel makkelijker.",
                    "list": ["HubSpot & Carerix", "Paige & Carerix", "HubSport & Paige", "HubSpot & AFAS (lanceert binnenkort)", "Paige & AFAS (lanceert binnenkort)", "Custom connections"]
                  },
                  {
                    "title": "Analyze your data", 
                    "text": "Wat doe je met de data die je hebt verzameld? Het is belangrijk dat je weet wat deze data betekent en hoe je het in het voordeel van je bedrijf kunt laten werken. Alleen dan ben je in staat om data driven beslissingen te maken die een duurzame groei bevorderen.",
                    "list": ["Segmentatie", "Rapporten", "Dashboarding"]
                  },
                  {
                    "title": "Using your data", 
                    "text": "Je kunt nog zoveel data verzamelen, als je dit niet op de juiste manier gebruikt mis je een belangrijke asset in de groei van je bedrijf. Door je data in te zetten op verschillende vlakken van marketing zorg je ervoor dat je niet voor je systemen werkt, maar de systemen voor jou werken. Bijvoorbeeld een e-mail automation op je klanten, gebruikers en/of kandidaten.",
                    "list": ["Retentie marketing", "Email automatisering", "Slimme content", "Retargeting", "Automatische notificaties"]
                  },
                  {
                    "title": "Support your people", 
                    "text": "Uiteindelijk draait het allemaal om je klanten. Uitmuntende support leveren zorgt ervoor dat ze op lange termijn ambassadeurs worden van jouw merk. En dat is goud waard. Hiervoor zetten we verschillende tools in om top of mind te blijven bij onze klanten en ze te motiveren opnieuw klant te worden.",
                    "list": ["Geautomatiseerde support e-mails", "Review & kwaliteit scoring", "Brand ambassadeurs & partnerschap"]
                  }
                ]}
                padding={{ top: 60, bottom: 20, left: 15, right: 15 }}
              />
            </Col>
          </Row>
        </Container>
        <Cta
          verticalStyling={true}
          title=""
          intro="Wij laten je systemen samenwerken zodat ze voor jou gaan werken."
          link="/"
          linkText="Binnenkort meer"
          className="pb-lg-0"
        />
        <ThreeImagesGallery
          imageBig={"loyall-label-integraties-portfolio-1.jpg"}
          imageSmall1={"loyall-label-integraties-portfolio-2.jpg"}
          imageSmall2={"loyall-label-integraties-portfolio-3.jpg"}
          padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
          delay={250}
          animationDuration={600}
        />
        <Cta
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default IntegrationsPage
